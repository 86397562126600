import React from 'react';
import Icons from '../../../assets/svg-icon/svg';
import IconColors from '../../../IconColors';
const AutoCompleteSearchIcon = (props) => {
    try {
        let { redirection } = props;
        return (
            <button type="button" aria-label="search_icon" className="su__btn su__search_btn su__animate-zoom su__flex-vcenter su__position-absolute su__zindex su__bg-transparent su__rtlleft" onClick={(e) => { console.log(e); redirection() }}>
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
                    <defs>
                        <clipPath id="clip-path">
                            <rect id="Rectangle_5199" data-name="Rectangle 5199" width="20" height="20" fill="none" />
                        </clipPath>
                    </defs>
                    <g id="Group_5624" data-name="Group 5624" transform="translate(-41 -165)">
                        <g id="Group_5620" data-name="Group 5620" transform="translate(41 165)">
                            <rect id="Rectangle_5203" data-name="Rectangle 5203" width="20" height="20" fill="rgba(255,255,255,0.83)" />
                        </g>
                        <g id="Group_5625" data-name="Group 5625" transform="translate(41 165)">
                            <g id="Group_5607" data-name="Group 5607" clip-path="url(#clip-path)">
                                <path id="Path_13454" data-name="Path 13454" d="M22.967,96.749a1.613,1.613,0,1,0-.825-.228l-.753,2.729h0a1.6,1.6,0,0,0-1.079.416L17.915,98.14a1.611,1.611,0,1,0-3.093-.9H11.3a1.611,1.611,0,1,0,0,.854h3.524a1.61,1.61,0,0,0,2.633.768l2.394,1.527a1.611,1.611,0,1,0,2.364-.908l.753-2.729h0M9.744,98.421a.757.757,0,1,1,.757-.757.758.758,0,0,1-.757.757m6.631,0a.757.757,0,1,1,.757-.757.758.758,0,0,1-.757.757m5.013,3.2a.757.757,0,1,1,.757-.757.758.758,0,0,1-.757.757m1.579-7.237a.757.757,0,1,1-.757.757.758.758,0,0,1,.757-.757" transform="translate(-7.757 -89.835)" fill="#f48b00" />
                                <path id="Path_13455" data-name="Path 13455" d="M12.347,0A7.529,7.529,0,0,0,5.8,11.246L4.166,12.877l-.358-.358a.427.427,0,0,0-.6.6l.358.358L.588,16.455a2.006,2.006,0,1,0,2.837,2.837L6.4,16.318l.358.358a.427.427,0,1,0,.6-.6L7,15.713l1.631-1.631A7.531,7.531,0,1,0,12.347,0M2.82,18.688a1.152,1.152,0,0,1-1.629-1.629l2.974-2.974,1.629,1.629ZM6.4,15.11,4.77,13.481l1.5-1.5A7.6,7.6,0,0,0,7.9,13.607Zm5.949-.9a6.678,6.678,0,1,1,6.678-6.678,6.685,6.685,0,0,1-6.678,6.678" transform="translate(0.06 0.06)" fill="#3c4257" />
                            </g>
                        </g>
                    </g>
                </svg>
            </button >
        );
    } catch (e) {
        console.log('Error in Search Icon component', e);
        return (<div></div>);
    }
};

export default React.memo(AutoCompleteSearchIcon);